import "../new/new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios"

const IntorductionText = () => {
    const [chatValues, setChatValues] = useState({});
    const authId = localStorage.getItem("authId");

    const handleChange = (e) => {
        setChatValues({ ...chatValues, [e.target.name]: e.target.value })
        console.log("chatValues", chatValues)
    }

    const handleSubmit = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/chat/wedding-details`,
                {
                    authId: authId,
                    title: chatValues.title1,
                    desc: chatValues.subTitle1,
                    subTitle: chatValues.title2,
                    subDesc: chatValues.subTitle2,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "X-Requested-With",
                    },
                })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="flex">
            <Sidebar />
            <div className="flex flex-col w-10/12">
                <Navbar />
                <div className="p-6">
                    <input type="text" name="title1" placeholder="Title 1" className="border-stone-400 border-2 h-[45px] w-[100%] max-w-[500px] mb-[25px] px-2 rounded-sm block" onChange={(e) => handleChange(e)} required />

                    <input type="text" placeholder="SubTitle 1" name="subTitle1" className="border-stone-400 border-2 h-[45px] w-[100%] max-w-[500px] mb-[25px] px-2 rounded-sm ml-0 block" onChange={(e) => handleChange(e)} required />

                    <input type="text" placeholder="title2" name="title2" className="border-stone-400 border-2 h-[45px] w-[100%] max-w-[500px] mb-[25px] px-2 rounded-sm block" onChange={(e) => handleChange(e)} required />

                    <input type="text" placeholder="SubTitle 2" className="border-stone-400 border-2 h-[45px] w-[100%] max-w-[500px] mb-[25px] px-2 rounded-sm ml-0 block" name="subTitle2" onChange={(e) => handleChange(e)} required />

                    <button className="flex items-center px-6 py-2 bg-[#7451f8] text-white mt-4 rounded-sm" type="submit" onClick={handleSubmit}>Add Intorduction Details</button>
                </div>
            </div>
        </div>
    );
};

export default IntorductionText;
