// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* @tailwind base;\n@tailwind components;\n@tailwind utilities; */\n\n.create-btn {\n  cursor: pointer;\n}\n.text-base {\n  color: #9c27b0;\n}\n.listScroll::-webkit-scrollbar {\n    width: 0px;\n  }\n  \n", "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AACA;;sBAEsB;;AAEtB;EACE,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;IACI,UAAU;EACZ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap\");\n/* @tailwind base;\n@tailwind components;\n@tailwind utilities; */\n\n.create-btn {\n  cursor: pointer;\n}\n.text-base {\n  color: #9c27b0;\n}\n.listScroll::-webkit-scrollbar {\n    width: 0px;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
