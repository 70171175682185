import "../new/new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios"

const ChatSettings = () => {
  const [chatValues, setChatValues] = useState('');
  const authId = localStorage.getItem("authId");

  const handleSubmit = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/chat/fetchChat`,
        {
          authId: authId,
          link: chatValues
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "X-Requested-With",
          },
        })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-col w-10/12">
        <Navbar />
        <div className="p-6">
          <input type="text" placeholder="Chat" className="border-stone-400 border-2 h-[45px] w-[100%] max-w-[500px] px-2 rounded-sm" onChange={(e) => setChatValues(e.target.value)} />
          <button className="flex items-center px-6 py-2 bg-[#7451f8] text-white mt-4 rounded-sm" type="submit" onClick={handleSubmit} disabled={!chatValues}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default ChatSettings;
